* {padding: 0;margin: 0;text-decoration: none;list-style: none;box-sizing: border-box;font-family: 'Karla';font-family: 'Titillium Web';font-family: 'Cormorant';color: #fff;}
body, html {height: 100%;}
html {scroll-behavior: smooth;}
.navbarb, body{background-color: black;}



header{height: 100vh; background-image:url(../public/assets/images/background2.png);background-repeat: no-repeat; background-position: center; background-size: cover;}

.navbar{display: flex; padding: 2rem 5rem; justify-content: space-between; }
.navbar .logo{width: 7rem; height: auto;}
.navtext{display: flex; gap: 2rem;}
.navtext h3{font-size: 1.5rem;font-weight: 400; font-family: 'Titillium Web';}
.texto__plato{margin: auto; font-size: 2.2rem; text-align: center; font-family: 'Cormorant'; font-weight: 300;}

.papelb{display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 2rem;text-align: center;}
.barco{width: 15rem; height: auto;}
.papelb h2{font-size: 2rem;}
.papelb p{font-size: 1.3rem;line-height: 1.2;font-family: 'Titillium Web';}

.cocinerab{display: flex; justify-content: center; gap: 4rem; padding: 4rem 1rem;}
.chefimg{width: 100%; max-width: 700px;}
.ramen{display: flex; flex-direction: column; gap: 2rem;}
.ramenimg{width: 100%; max-width: 450px; height: auto;}
.ramen p{text-align: center; font-family: 'Titillium Web';}
.ramen span{font-weight: 700;font-family: 'Titillium Web';}
.ramen p, span{font-size: 1.3rem;}

.circulo {position: relative;display: flex;justify-content: center;}
.cmenu img {width: 100%;max-width: 250px;height: auto;}
.tituloc {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);font-size: 3.5rem;text-align: center;}

.menu{width: 100%; background-image:url(../public/assets/images/humobg.png);background-repeat: no-repeat; background-position: center; background-size: cover;}
.menu{display: flex; flex-direction: column; align-items: center; gap: 5rem; padding: 4rem 1rem;}
.comidas{display: flex; align-items: center; justify-content: center; gap: 1rem;}
.comida{display: flex; flex-direction: column; gap: 1rem;}
.comida img{width: 100%; max-width: 500px; transition: .5s;}
.comida picture{overflow: hidden;}
.comida img:hover{transform: scale(1.2);transition: .5s;}
.comida h4{font-size: 1.2rem; font-family: 'Titillium Web'; font-weight: 300; text-align: center;}
.menu h2{font-family: 'Titillium Web'; font-weight: 300; text-decoration: underline;}

footer .tcontacto{font-size: 2.5rem; text-align: center; padding: 4rem 1rem 1rem 1rem;}
.contactob{display: flex; align-items: center; justify-content: center; gap: 6rem; padding: 1rem 1rem 4rem 1rem;}
.horariotext{display: flex;flex-direction: column; gap: 1rem;}
.horario h3,.reservacion h3{font-size: 1.4rem;font-family: 'Titillium Web'; font-weight: 300;}
.horario p,.horariotext p,.reservacion p{font-size: 1rem;font-family: 'Titillium Web'; font-weight: 300;}
.naof{background-color: #333333; padding: 2rem 1rem;}
.naof h1{font-size: 1.2rem;font-family: 'Titillium Web'; font-weight: 300; text-align: center;}
.red{width: 3rem; height: auto;}
.platof{width: 100%;}

/*Responsive*/
@media(max-width:1500px){ 
    header{height: 100vh; background-image:url(../public/assets/images/bg@1500.png);background-repeat: no-repeat; background-position: center; background-size: cover;}
  }
  @media(max-width:1200px){
    header{height: 100vh; background-image:url(../public/assets/images/bg@1200.png);background-repeat: no-repeat; background-position: center; background-size: cover;}
    .comidas{flex-wrap: wrap;}
    .comida img{max-width: 300px;}
  }
  @media(max-width:1102px){
    .ramen p, .ramen span{font-size: 1.03rem;}
  }
  @media(max-width:900px){
    header{height: 90vh; background-image:url(../public/assets/images/bg@900.png);background-repeat: no-repeat; background-position: center; background-size: cover;}
    
    .cocinerab{flex-direction: column; align-items: center;}
    .ramen p, .ramen span{font-size: 1.2rem;}
  }
  @media(max-width:857px){
    .cmenu img{max-width: 150px;}
    .tituloc{font-size: 2rem;}
  }
  @media(max-width:600px){
    header{height: 70vh; background-image:url(../public/assets/images/bg@600.png);background-repeat: no-repeat; background-position: center; background-size: cover;}
    .platof{display: none;}
  }
  @media(max-width:500px){
    .navbar{flex-direction: column; align-items: center;}
    .texto__plato{font-size: 1.5rem;}

    .cmenu img{max-width: 100px;}
    .tituloc{font-size: 1.3rem;}
  }
  @media(max-width:400px){
    .barco{width: 12rem;}
    .papelb h2{font-size: 1.5rem;}
    .papelb p{font-size: 1rem;}

    .ramen p, .ramen span{font-size: 1rem;}
  }
  @media(max-width:332px){
    .ramen p, .ramen span{font-size: .9rem;}
  }
  